import { useMemo } from 'react';
import { useAppSelector } from 'lib/hooks';
import { buildOrderFormSelector } from 'lib/features/createOrderV2/selectors';
import {
  Offer, TeeOffer, TIdSource, OfferType,
} from 'generated/types';
import { Slots } from 'lib/features/createOrderV2/types';
import { useGetOfferQuery, useGetOfferTypeQuery } from 'lib/features/offers';
import { useGetTeeOfferQuery } from 'lib/features/teeOffers';
import {
  offerDataTransform, teeOfferDataTransform, Data,
} from '../helpers';
import { useGetDescription } from './useGetDescription';

export interface UseGetOfferResult {
  formatted?: Data;
  original?: TeeOffer | Offer;
  isTee?: boolean;
}

const useGetOffer = (id: string, slotsFromQuery?: Slots | null): UseGetOfferResult => {
  const { data: dataOfferType } = useGetOfferTypeQuery({ _id: id, mapTo: TIdSource.Blockchain }, {
    skip: !id,
  });
  const offerType = dataOfferType?.offerType;
  const isTee = offerType === OfferType.TeeOffer;
  const buildOrderForm = useAppSelector(buildOrderFormSelector);
  const { data: dataOffer } = useGetOfferQuery({ _id: id, mapTo: TIdSource.Blockchain }, {
    skip: !id || !offerType || isTee,
  });
  const { data: dataTeeOffer } = useGetTeeOfferQuery({ _id: id, mapTo: TIdSource.Blockchain }, {
    skip: !id || !offerType || !isTee,
  });

  const teeOffer = dataTeeOffer?.teeOffer as TeeOffer;
  const offer = dataOffer?.offer;

  const { description } = useGetDescription(offer?.offerInfo?.description);

  const formatted = useMemo(() => {
    if (teeOffer) {
      return teeOfferDataTransform(teeOffer, buildOrderForm, slotsFromQuery);
    }
    if (offer) {
      return offerDataTransform(offer, buildOrderForm, description);
    }
    return undefined;
  }, [teeOffer, offer, buildOrderForm, slotsFromQuery, description]);
  return {
    formatted,
    original: teeOffer || offer,
    isTee,
  };
};

export default useGetOffer;
