import { useCallback, useMemo } from 'react';

import { Box } from 'uikit/Box';
import { Offer as OfferType } from 'generated/types';
import { useQuickDeploy } from 'lib/features/createOrderV2/hooks/useQuickDeploy';
import { FieldsBuildOrderForm, SLOT_COUNT } from 'lib/features/createOrderV2/types';
import useGetOffer from './hooks/useGetOffer';
import { OfferProps } from './types';
import { Left } from './Left';
import { Right } from './Right';
import { Data } from './helpers';
import classes from './Offer.module.scss';

export const Offer = ({
  id, slotsFromQuery, tab, onChangeTab, canQuickDeploy: canQuickDeployProp,
}: OfferProps) => {
  const {
    quickDeployCatched, loading, quickAddContentCatched, quickDeleteContent,
  } = useQuickDeploy();
  const { original, formatted, isTee } = useGetOffer(id, slotsFromQuery);
  const { right, left, common } = useMemo(() => formatted || {} as Data, [formatted]);
  const onDeleteSlot = useCallback(() => {
    quickDeleteContent({ field: common?.fieldBuildOrderForm });
  }, [quickDeleteContent, common]);

  const onAddSlot = useCallback((slotId: string) => {
    return quickAddContentCatched({
      field: common?.fieldBuildOrderForm as FieldsBuildOrderForm.model,
      offerId: common?.id,
      data: original,
      slots: { slot: { id: slotId, count: SLOT_COUNT.VALUE_OFFER } },
    });
  }, [quickAddContentCatched, common, original]);

  const onAddOffer = useCallback(() => {
    quickAddContentCatched({
      field: common?.fieldBuildOrderForm, offerId: common?.id, data: original, slots: slotsFromQuery,
    });
  }, [quickAddContentCatched, common, original, slotsFromQuery]);

  const onDelete = useCallback(() => {
    quickDeleteContent({ field: common?.fieldBuildOrderForm });
  }, [quickDeleteContent, common]);

  const onDeploySlot = useCallback((slotId: string) => {
    return quickDeployCatched({
      field: common?.fieldBuildOrderForm as FieldsBuildOrderForm.model,
      offerId: common?.id,
      data: original,
      slots: { slot: { id: slotId, count: SLOT_COUNT.VALUE_OFFER } },
    });
  }, [common, quickDeployCatched, original]);

  const canQuickDeploy = useMemo(
    () => canQuickDeployProp && [
      FieldsBuildOrderForm.model,
      FieldsBuildOrderForm.datasets,
    ].includes(common?.fieldBuildOrderForm as FieldsBuildOrderForm),
    [common, canQuickDeployProp],
  );

  const canGroupByCPUAndGPU = useMemo(
    () => [FieldsBuildOrderForm.model].includes(common?.fieldBuildOrderForm as FieldsBuildOrderForm),
    [common],
  );

  return (
    <Box className={classes.content}>
      <Left {...{
        data: left,
        selectedSlots: common?.selectedSlots,
        loading,
        ...(canQuickDeploy ? { onDeploySlot } : { onAddSlot }),
        onDeleteSlot,
        isCanUpdateSlot: !isTee && original?.enabled,
        isShowCounter: isTee && common?.isAdded,
        canGroupByCPUAndGPU,
        isTee,
        tab,
        onChangeTab,
        isVerified: !!(original as OfferType)?.verifiedAt,
      }}
      />
      <Right
        {...{
          list: right?.list,
          price: common?.price,
          id,
          loading,
          isAdded: common?.isAdded,
          isShowAddBtn: isTee,
        }}
        onAdd={onAddOffer}
        onDelete={onDelete}
      />
    </Box>
  );
};
