import { OfferType, ValueOfferSubtype, TeeOfferSubtype } from '@super-protocol/sdk-js';
import { BigNumber } from 'bignumber.js';
import {
  TeeOffer,
  HardwareInfo,
  OfferSlot,
  TeeOfferSlot,
  TeeOfferOption,
} from 'generated/types';
import {
  BuildOrderForm, FormContentKey, Slots,
} from 'lib/features/createOrderV2/types';
import {
  getFieldBuildOrderdByOfferType, getSelectedSlot, checkAddContent, getOfferPriceBySlots, getDefaultSlot,
} from 'lib/features/createOrderV2/helpers';
import {
  getTableDate,
} from 'utils';
import {
  getCategoriesByConfiguration, getTeeOfferSubTypeName, getValueOfferSubTypeName, getFixedDeposit,
  offerSubTypeName,
} from 'utils/sdk/utils';
import {
  convertSlotInfo, convertOptionData, convertSlotUsageInfo, priceTypeMap,
} from 'utils/slots';
import { TOKEN_NAME } from 'common/constants';
import { TransformedOffer } from 'lib/features/offers/types';
import { Description, DescriptionType } from 'components/OfferAbout/types';
import { LeftProps, Configuration } from './Left/types';
import { RightProps, RighListComponent } from './Right/types';

export enum SlotType {
  CPU = 'CPU',
  GPU = 'GPU'
}

export interface Data {
  common: {
    price: {
      type: string;
      sum: string;
    }[];
    restrictions: string[];
    fieldBuildOrderForm?: FormContentKey;
    selectedSlots?: Slots | null;
    id: string;
    isAdded: boolean;
  };
  left: LeftProps;
  right: {
    list: RightProps['list'],
  };
}

const sortSlots = (slotA: OfferSlot | TeeOfferSlot, slotB: OfferSlot | TeeOfferSlot) => {
  const { priceType: priceTypeA, price: priceA } = slotA?.usage || {};
  const { priceType: priceTypeB, price: priceB } = slotB?.usage || {};
  if (priceTypeA < priceTypeB) return -1;
  if (priceTypeA > priceTypeB) return 1;
  const priceABn = new BigNumber(priceA);
  const priceBBn = new BigNumber(priceB);
  if (priceABn.isGreaterThan(priceBBn)) return 1;
  if (priceBBn.isGreaterThan(priceABn)) return -1;
  return 0;
};

export const getSlotType = (subType?: ValueOfferSubtype, slotType?: SlotType): string => {
  switch (slotType) {
    case SlotType.CPU:
      return `Deploy ${offerSubTypeName[subType as string].toLowerCase()} on CPU`;
    case SlotType.GPU:
      return `Deploy ${offerSubTypeName[subType as string].toLowerCase()} on GPU`;
    default:
      return '';
  }
};

const getOfferSlots = (slots?: OfferSlot[], subType?: ValueOfferSubtype) => {
  return ([...(slots || [])])
    .sort(sortSlots)
    .map((slot) => {
      const slotType = slot?.info?.vram > 0 ? SlotType.GPU : SlotType.CPU;
      return {
        id: {
          label: 'slotId',
          value: slot?.id,
          id: slot?.id,
        },
        title: {
          label: priceTypeMap[slot?.usage?.priceType] || '',
          value: `${getFixedDeposit({ deposit: slot?.usage?.price })} ${TOKEN_NAME}`,
          id: slot?.usage?.priceType,
        },
        list: [
          convertSlotInfo({ slotInfo: slot?.info, keys: ['cpuCores', 'ram', 'gpuCores', 'vram'] }),
          [
            ...convertSlotInfo({ slotInfo: slot?.info, keys: ['diskUsage'] }),
            ...convertOptionData({
              optionInfo: slot?.option ? [{ option: slot.option, count: 1 }] : [],
              keys: ['bandwidth', 'traffic', 'externalPort'],
            }),
          ],
          convertSlotUsageInfo({ slotUsage: slot?.usage, keys: ['minTimeMinutes', 'maxTimeMinutes'] }),
        ],
        options: {
          slotType: {
            value: slotType,
            label: getSlotType(subType, slotType),
          },
        },
      };
    });
};

export enum SourceTypes {
  HUGGING_FACE = 'Hugging Face'
}

export enum SourceTypesUrls {
  'https://huggingface.co' = SourceTypes.HUGGING_FACE,
}

export const getSourceType = (url?: string): string | null => {
  if (!url) return null;
  return Object.entries(SourceTypesUrls).find(([key]) => url.startsWith(key))?.[1] || null;
};

export const prepareModelFormats = (offer: TransformedOffer): Record<string, string[]> | null => {
  if (!offer) return null;
  const { slots } = offer;
  if (!slots?.length) return null;
  return slots
    .map((slot) => {
      const { name, quantization, format } = slot?.transformedMetadata || {};
      return {
        groupId: [name, quantization, format].filter(Boolean).join(' '),
        id: slot.id,
      };
    })
    .reduce((acc, { groupId, id }) => {
      if (groupId) {
        acc[groupId] = [...(acc[groupId] || []), id];
      }
      return acc;
    }, {});
};

const prepareOfferRightData = (offer: TransformedOffer) => {
  const data: RightProps['list'] = [];
  if (!offer) return data;

  const {
    id, offerInfo, providerInfo, origins, disabledAfter, configuration, versions, slots,
  } = offer;
  const { subType } = offerInfo || {};

  if (id) data.push({ value: id, title: 'Offer ID:' });

  const subTypeName = getValueOfferSubTypeName(subType as ValueOfferSubtype);
  if (subTypeName) data.push({ value: subTypeName, title: 'Offer Type:' });

  if (providerInfo?.name) data.push({ value: providerInfo?.name, title: 'Provider:' });

  const categories = getCategoriesByConfiguration(configuration, offerInfo.subType as ValueOfferSubtype);
  if (offerInfo.subType === ValueOfferSubtype.ValueSubtypeModel && categories?.length) {
    data.push({ value: categories, title: 'Category:' });
  }

  const parameters = (slots || []).find((slot) => slot?.transformedMetadata?.parameters)?.transformedMetadata?.parameters || null;
  if (parameters) data.push({ value: parameters, title: 'Parameters:' });

  const { url } = versions?.[(versions?.length || 0) - 1]?.info?.transformedMetadata || {};
  const sourceType = getSourceType(url);
  if (url && sourceType) {
    data.push({
      value: url, component: RighListComponent.link, title: 'Source:', data: sourceType,
    });
  }

  data.push({ value: getTableDate(origins?.createdDate), title: 'Published:' });

  if (disabledAfter) data.push({ value: disabledAfter.toString(), title: 'Disabled After:' });

  data.push({ value: getTableDate(origins?.modifiedDate), title: 'Updated:' });

  return data;
};

const getDefaultSelectedModelFormat = (
  modelFormats: Record<string, string[]> | null,
  defaultSlot: OfferSlot | TeeOfferSlot | null,
  selectedSlot: Slots | null,
): string | null => {
  if (!modelFormats) return null;
  const slotId = selectedSlot?.slot?.id || defaultSlot?.id;
  if (!slotId) return null;
  return Object.entries(modelFormats).find(([, ids]) => ids.includes(slotId))?.[0] || null;
};

export const offerDataTransform = (
  data: TransformedOffer,
  buildOrderForm: BuildOrderForm,
  description: Description,
): Data | undefined => {
  if (!data) return undefined;

  const {
    id, offerInfo, slots,
  } = data;
  const field = getFieldBuildOrderdByOfferType(offerInfo?.subType as ValueOfferSubtype, 'ValueOfferSubtype');
  const selectedSlots = getSelectedSlot(buildOrderForm, field, id);
  const subType = getValueOfferSubTypeName(offerInfo.subType as ValueOfferSubtype);
  const modelFormats = prepareModelFormats(data);
  const defaultSlot = getDefaultSlot(slots);
  const defaultSelectedModelFormat = getDefaultSelectedModelFormat(modelFormats, defaultSlot, selectedSlots);

  const isAdded = checkAddContent({
    formContent: buildOrderForm,
    field,
    offerId: id,
  });

  return {
    common: {
      price: getOfferPriceBySlots(selectedSlots, slots),
      restrictions: offerInfo?.restrictions?.offers || [],
      fieldBuildOrderForm: field,
      id,
      selectedSlots,
      isAdded,
    },
    left: {
      name: offerInfo.name,
      about: description,
      restricted: offerInfo.restrictions?.offers || [],
      type: offerInfo.offerType as OfferType,
      slots: getOfferSlots(slots, offerInfo.subType as ValueOfferSubtype),
      subType,
      options: [],
      modelFormats,
      defaultSelectedModelFormat,
    },
    right: {
      list: prepareOfferRightData(data),
    },
  };
};

export const getTEEConfiguration = (hardwareInfo: HardwareInfo): Configuration => {
  if (!hardwareInfo) return { slots: [], options: [] };
  const { slotInfo, optionInfo } = hardwareInfo || {};
  return {
    slots: convertSlotInfo({ slotInfo }),
    options: convertOptionData(optionInfo ? { optionInfo: [{ option: optionInfo, count: 1 }] } : {}),
  };
};

const getTeeOfferSlots = (slots?: TeeOfferSlot[]) => {
  return ([...(slots || [])])
    .sort(sortSlots)
    .map((slot) => ({
      id: {
        label: 'slotId',
        value: slot?.id,
        id: slot?.id,
      },
      title: {
        label: priceTypeMap[slot?.usage?.priceType] || '',
        value: `${getFixedDeposit({ deposit: slot?.usage?.price })} ${TOKEN_NAME}`,
        id: slot?.usage?.priceType,
      },
      list: [
        convertSlotInfo({ slotInfo: slot?.info, keys: ['cpuCores', 'ram', 'diskUsage'] }),
        convertSlotInfo({ slotInfo: slot?.info, keys: ['gpuCores', 'vram'] }),
        convertSlotUsageInfo({ slotUsage: slot?.usage, keys: ['minTimeMinutes', 'maxTimeMinutes'] }),
      ],
    }));
};

const getTeeOfferOptions = (options?: TeeOfferOption[]) => {
  return (options || [])
    .map((option) => ({
      id: {
        label: 'optionId',
        value: option?.id,
        id: option?.id,
      },
      title: {
        label: priceTypeMap[option?.usage?.priceType] || '',
        value: `${getFixedDeposit({ deposit: option?.usage?.price })} ${TOKEN_NAME}`,
        id: option?.usage?.priceType,
      },
      list: [
        convertOptionData({
          optionInfo: option?.info ? [{ option: option.info, count: 1 }] : [],
          keys: ['bandwidth', 'traffic', 'externalPort'],
        }),
        convertSlotUsageInfo({ slotUsage: option?.usage, keys: ['minTimeMinutes', 'maxTimeMinutes'] }),
      ],
    }));
};

const prepareTeeOfferRightData = (offer: TeeOffer) => {
  const data: RightProps['list'] = [];
  if (!offer) return data;

  const {
    id, providerInfo, origins, disabledAfter, teeOfferInfo,
  } = offer;

  if (id) data.push({ value: id, title: 'Offer ID:' });

  if (providerInfo?.name) data.push({ value: providerInfo?.name, title: 'Provider:' });

  const subTypeName = getTeeOfferSubTypeName(teeOfferInfo?.subType as TeeOfferSubtype);
  if (subTypeName) data.push({ value: subTypeName, title: 'Offer Type:' });

  data.push({ value: getTableDate(origins?.createdDate), title: 'Published:' });

  if (disabledAfter) data.push({ value: disabledAfter.toString(), title: 'Disabled After:' });

  data.push({ value: getTableDate(origins?.modifiedDate), title: 'Updated:' });

  return data;
};

export const teeOfferDataTransform = (
  data: TeeOffer | undefined,
  buildOrderForm?: BuildOrderForm,
  slotsFromQuery?: Slots | null,
): Data | undefined => {
  if (!data) return undefined;

  const {
    id, teeOfferInfo, slots, options,
  } = data;
  const { hardwareInfo } = teeOfferInfo || {};
  const field = getFieldBuildOrderdByOfferType(teeOfferInfo?.subType as TeeOfferSubtype, 'TeeOfferSubtype');

  const isAdded = checkAddContent({
    teeOfferId: id,
    field,
    formContent: buildOrderForm,
    slots: slotsFromQuery,
  });

  const subType = getTeeOfferSubTypeName(teeOfferInfo?.subType as TeeOfferSubtype);

  return {
    common: {
      price: getOfferPriceBySlots(slotsFromQuery, slots, options),
      restrictions: [],
      id,
      fieldBuildOrderForm: field,
      selectedSlots: slotsFromQuery,
      isAdded,
    },
    left: {
      name: teeOfferInfo.name,
      about: { value: teeOfferInfo.description, type: DescriptionType.html },
      restricted: [],
      type: OfferType.TeeOffer,
      slots: getTeeOfferSlots(slots),
      options: getTeeOfferOptions(options),
      configuration: getTEEConfiguration(hardwareInfo),
      subType,
      modelFormats: null,
      defaultSelectedModelFormat: null,
    },
    right: {
      list: prepareTeeOfferRightData(data),
    },
  };
};
